.Assistant {
  @keyframes blur-assistant-background {
    from {
      backdrop-filter: blur(0px);
      z-index: -10;
    }

    50% {
      backdrop-filter: blur(0px);
      z-index: -10;
    }
  
    to {
      backdrop-filter: blur(3px);
      z-index: 600;
    }
  }

  @keyframes grow-assistant {
    from {
      height: 3rem;
      transform: translateX(calc(100% - 3rem));
      z-index: 29;
    }

    45% {
      height: 3rem;
      transform: translateX(-5%);
      z-index: 600;
    }

    50% {
      height: 3rem;
      transform: translateX(-5%);
      z-index: 600;
    }
  
    to {
      height: 8.5rem;
      transform: translateX(-5%);
      z-index: 600;
    }
  }

  @keyframes initial-load-assistant {
    from {
      transform: translateX(calc(100% + 1rem));
    }
  
    to {
      transform: translateX(calc(100% - 3rem));
    }
  }
  
  @keyframes shrink-assistant {
    from {
      height: 8.5rem;
      transform: translateX(-5%);
      z-index: 600;
    }

    50% {
      height: 3rem;
      transform: translateX(-5%);
      z-index: 600;
    }

    55% {
      height: 3rem;
      transform: translateX(-5%);
      z-index: 600;
    }
  
    to {
      height: 3rem;
      transform: translateX(calc(100% - 3rem));
      z-index: 29;
    }
  }

  @keyframes unblur-assistant-background {
    from {
      backdrop-filter: blur(3px);
      z-index: 600;
    }

    50% {
      backdrop-filter: blur(0px);
      z-index: 600;
    }
  
    to {
      backdrop-filter: blur(0px);
      z-index: -10
    }
  }

  &.hidden {
    display: none;
  }

  #pop-out-background-blur {
    height: 100dvh;
    position: fixed;
    transition: 0.75s $ExpoEaseOut;
    width: 100dvw;
    will-change: backdrop-filter, z-index;
  }

  #pop-out-tab {
    background: white;
    border-radius: 2rem;
    bottom: 15dvh;
    box-shadow: 0px 0rem 1rem #7c7c7c47;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: bold;
    justify-content: flex-start;
    overflow: hidden;
    position: fixed;
    right: 0;
    will-change: height, transform, z-index;

    #assistant-label-row {
      border-radius: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 3rem;
      min-height: 3rem;
      transition: 0.25s;

      #pop-out-close-button {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 1rem;

        img {
          width: 1.5rem;
        }
      }

      #pop-out-title {
        color: $selectionBackgroundColor;
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        font-weight: bold;
        justify-content: center;
        opacity: 1;
        transition: 0.25s;
        transition-delay: 0.4s;
        width: 100%;
      }

      .pop-out-caret {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0rem;
        padding: 0 1rem;
        transition: 0.25s;

        img {
          height: 2rem;
          width: 2rem;
        }
      }
    }

    #preview-page-pop-out-content {
      display: flex;
      flex-direction: column;
      min-height: 5.5rem;
      justify-content: start;
      padding: 0.25rem 0;
      transition: 0.25s $ExpoEaseOut;
      user-select: none;
      -ms-user-select: none;
      -webkit-user-select: none;

      > :nth-child(2) {
        &::before {
          background: $selectionBackgroundColor;
          content: "";
          height: 1px;
          opacity: 0.25;
          position: absolute;
          width: calc(100% - 2.6rem);
        }
      }

      .content-row {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        font-weight: bold;
        justify-content: space-between;
        line-height: 2.5rem;
        padding: 0 1rem 0 1.5rem;

        .icon {
          display: flex;
          flex-direction: column;
          justify-content: center;

          img {
            width: 1rem;
          }
        }

        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }

  &.center {
    #pop-out-background-blur {
      animation-duration: 0.75s;
      animation-direction: normal;
      animation-name: blur-assistant-background;

      backdrop-filter: blur(3px);
      z-index: 600;
    }

    #pop-out-tab {
      animation-duration: 0.75s;
      animation-direction: normal;
      animation-name: grow-assistant;

      border-radius: 1.5rem;
      height: 8.5rem;
      transform: translateX(-5%);
      width: 91%;
      z-index: 601;

      #assistant-label-row {
        box-shadow: 0px 0rem 0.25rem #7c7c7c47;
        transition-delay: 0.4s;

        .pop-out-caret {
          margin-left: -2rem;
        }

        #pop-out-title {
          opacity: 1;
        }
      }

      #preview-page-pop-out-content {
        transition-delay: 0.4s;
      }
    }
  }

  &.initial-load {  
    #pop-out-background-blur {
      backdrop-filter: blur(0px);
      z-index: -10;
    }

    #pop-out-tab {
      animation-duration: 0.25s;
      animation-direction: normal;
      animation-name: initial-load-assistant;

      border-radius: 2rem;
      height: 3rem;
      transform: translateX(calc(100% - 3rem));
      width: 91%;
      z-index: 29;

      #assistant-label-row {
        .pop-out-caret {
          margin-left: 0;
        }
      }
    }
  }

  &.side {
    #pop-out-background-blur {
      animation-duration: 0.75s;
      animation-direction: normal;
      animation-name: unblur-assistant-background;
      z-index: -10;

      backdrop-filter: blur(0px);
      z-index: -10;
    }

    #pop-out-tab {
      animation-duration: 0.75s;
      animation-direction: normal;
      animation-name: shrink-assistant;

      border-radius: 2rem;
      height: 3rem;
      transform: translateX(calc(100% - 3rem));
      width: 91%;
      z-index: 29;

      #assistant-label-row {
        transition-delay: 0.4s;
        
        .pop-out-caret {
          margin-left: 0;
          transition-delay: 0.4s;
        }
      }
    }
  }
}
