.formRow {
  & + .formRow {
    margin-top: 16px;
  }

  input,
  textarea {
    box-shadow: inset 3px 3px 7px #cecece, inset -3px -3px 7px #ffffff;
    // border-bottom: 1px solid transparentize(#000, 0.85);
    border-radius: 1rem;
    // border: 1px solid #80808066;
    caret-color: transparentize(#000, 0.85);
    display: block;
    padding: 10px 10px;
    width: 100%;
    
    @include fontSize(16, 20);
    font-family: $baseFontFamily;

    &:focus::placeholder {
      color: transparent;
    }
  }

  textarea {
    overflow: hidden;
    resize: none;
  }

  &_label {
    color: $cDarker;
    @include fontSize(14, 16);
    font-weight: 600;

    .lock-icon {
      filter: grayscale(100);
      font-size: 0.75rem;
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &_description {
    @include fontSize(12, 16);
    color: $gray500;
    margin-top: 4px;
  }

  &_message {
    margin-top: 4px;
    color: $error;
  }

  &--security {
    input {
      @include fontSize(56, 56);
      text-transform: uppercase;
      text-align: center;
      width: 80%;
      margin: auto;
    }
  }

  &--checkbox {
    border-bottom: 1px solid #d5d5d5;

    .formRow_label {
      font-weight: 400;
      position: relative;
      display: flex;
      justify-content: space-between;
      color: currentColor;
      @include fontSize(16, 20);
      padding: 5px 0;
    }

    input {
      position: absolute;
      visibility: hidden;
      height: 0;
      width: 0;

      &:checked {
        & + span {
          background-color: $cDark;

          &::after {
            left: calc(100% - 18px - 3px);
          }
        }
      }
    }

    span.pretty-toggle {
      position: relative;
      right: 0;
      display: inline-block;
      width: 42px;
      height: 24px;
      border-radius: 24px;
      background-color: $gray400;
      transition: background-color 0.2s linear;

      &::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        background-color: #fff;
        transition: background-color 0.2s linear, left 0.6s $ExpoEaseOut;
      }
    }
  }

  &--radio {
    background-color: $gray100;
    border-radius: 8px;

    & + .formRow--radio {
      margin-top: 0;
    }

    .formRow_label {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: currentColor;
      @include fontSize(16, 20);
      padding: 12px;
    }

    input {
      position: absolute;
      visibility: hidden;
      height: 0;
      width: 0;

      &:checked {
        & + span {
          opacity: 1;
        }
      }
    }

    span.check {
      opacity: 0;
      position: relative;
      @include iconCheck();
      transition: opacity 0.6s $ExpoEaseOut;
    }
  }

  &--ai {
    padding: 0 0 14px;

    &,
    textarea {
      position: relative;
    }

    &,
    textarea,
    .icon {
      transition: all 0.6s $ExpoEaseOut;
    }

    textarea {
      border: 1px solid $gray200;
      border-radius: 12px;
      overflow-y: scroll;
      padding: 12px;

      &::-webkit-scrollbar {
        height: 0rem;
        width: 0rem;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }

    .formRow_cols {
      display: grid;
      grid-template-columns: 1fr 32px;
      gap: 8px;
      align-items: end;
    }

    .formRow_generate {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      border: 2px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    
      span {
        display: block;
        width: 16px;
        margin: -2px -2px 0 0;
      }
    
      svg {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &.active {
      background-color: $gray700;

      .icon {
        opacity: 1;
        scale: 1;
      }
    }

    .formRow_message {
      margin: 4px 0 0;
      color: $gray400;
      text-align: right;
    }
  }
}

.radioButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;

  label {
    cursor: pointer;
    position: relative;
    text-align: center;
    flex: 1;

    &.locked {
      pointer-events: none;
    }

    input {
      position: absolute;
      visibility: hidden;
      height: 0;
      width: 0;

      &:checked + span {
        background-color: $cDarker;
        color: #fff;
      }
    }

    span {
      display: block;
      padding: 10px 16px 6px;
      border: 1px solid $cDarker;
      transition: background-color 0.2s linear, color 0.2s linear,
        border-color 0.2s linear;

      &.locked {
        color: lighten($cDark, 50%);
        pointer-events: none;

        &::after {
          background: #e1e1e1;
          border-radius: 100px;
          border: 1px solid black;
          bottom: -15px;
          color: $cDark;
          content: 'Coming Soon';
          display: block;
          filter: grayscale(100);
          font-size: 0.75rem;
          left: 50%;
          padding: 0.1rem 0.4rem;
          position: absolute;
          transform: translate(-50%, 0%);
          white-space: nowrap;
        }
      }
    }
  }
}

label.radio-label {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

label.toggle-label {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.inputFile {
  // background-color: $pearl;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #f27c66;
  // background-image: linear-gradient($gray50, $gray50),
  //   linear-gradient(90deg, #f27c66, #fecd8f);
  // padding: 12px 16px;
}

.inputFile__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  line-height: normal;
  font-family: inherit;
  font-size: 100%;
  margin: 0;

  & + label {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    color: $cDark;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.inputFile__icon {
  display: flex;
  position: absolute;
}

.inputFile__remove {
  width: 32px;
  height: 32px;
  padding: 8px 9px;
  background-color: $white;
  position: absolute;
  top: -11px;
  right: -11px;
  border-radius: 100%;
}

.inputFile__thumbnail {
  cursor: pointer;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img {
    @keyframes pulseOpacity {
      0% {
        opacity: 1;
      }
      40% {
        opacity: 0.3;
      }
      100% {
        opacity: 1;
      }
    }

    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    aspect-ratio: 1/1;

    &.regenerating {
      -webkit-animation: pulseOpacity 2.5s ease-out;
      -webkit-animation-iteration-count: infinite;
      opacity: 1;
    }
  }
}
