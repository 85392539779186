.container {
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }
}

.data {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: #ccc;
  overflow: hidden;
}
