@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 48px 80px;
  flex: 1;
}

.heading {
  position: relative;
  margin-bottom: 30px;

  button {
    display: inline-flex;
    padding: 10px;
    width: 36px;
    position: absolute;
    top: -20px;
    right: -10px;

    svg {
      display: block;
      width: 100%;
    }
  }
}

.content {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}

.loading {
  flex-shrink: 0;
  margin-left: 100%;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 8px;
  margin-bottom: 36px;
  width: 100%;
}

.field {
  box-shadow: inset 3px 3px 7px #cecece, inset -3px -3px 7px #ffffff;
  // background-color: $white;
  border-radius: 1rem;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  position: relative;

  &.text {
    input,
    input::placeholder,
    textarea,
    textarea::placeholder {
      flex-grow: 1;
      color: $cDark;
    }

    &.text {
      input::placeholder,
      textarea::placeholder {
        flex-grow: 1;
        color: lighten($cDark, 30%);
      }
    }

    input {
      height: 32px;
    }

    textarea {
      height: 64px;
      padding-right: 32px;
      resize: none;
    }
  }
}

.field__col {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 16px 8px 0;
  height: 100%;
  display: flex;
  align-items: center;
  
  .field.text & {
    pointer-events: none;
  }
}

.field__icon {
  width: 32px;
  height: 32px;
  background-color: $gray50;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:disabled {
    span {
      opacity: 0.5;
    }
  }
}

.addImage {
  border: 1px dashed $cDark;
  width: 120px;
  height: 180px;
  background-color: $gray50;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
}

.hasImage {
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &.isLoading {
    img {
      width: 40%;
      height: 40%;
      top: 30%;
      left: 30%;
      object-fit: contain!important;
    }
  }
}

.cta {
  box-shadow:  3px 3px 7px #bebebe, -3px -3px 7px #ffffff;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 9999px;
  color: $cDark;
  cursor: pointer;
  @include fontSize(18, 24);
  font-weight: 600;
}

.cta__text {
  padding: 10px;
  position: relative;
}

@keyframes fadeIn { 
  from { opacity: 0; } 
}

.cta__text__loading {
  animation: fadeIn 1s infinite alternate;
  pointer-events: none;
}

.cta__icon {
  display: flex;
  align-items: center;
  width: 16px;
  position: absolute;
  top: 0;
  right: 16px;
  height: 100%;
  transform: translateY(-2px);

  svg {
    display: block;
    width: 100%;
  }
}
