.main {
  .page_content {
    &.terms-of-use-page {
      -ms-overflow-style: none;
      height: 100%;
      overflow: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        height: 0rem;
        width: 0rem;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
      
      h1 {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.5rem;
      }

      h3 {
        font-size: 1rem;
        font-weight: bold;
      }

      ul {
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;

        li {
          list-style: disc;
        }
      }
    }
  }
}
