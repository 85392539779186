/////////////////////////////////////
// Breakpoints //////////////////////
/////////////////////////////////////

$lg-break: 600px;

@mixin breakpoint($width, $minmax: 'max') {
  @media screen and (#{$minmax}-width: #{$width}) {
    @content;
  }
}

@mixin mobile($break: $lg-break) {
  @include breakpoint($break, 'max') {
    @content;
  }
}

@mixin desktop() {
  @include breakpoint($lg-break, 'min') {
    @content;
  }
}
