@import 'assets/styles/abstracts/variables';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 32px;
  gap: 8px;

  &.multiple {
    margin-bottom: 16px;
  }
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  button {
    flex-grow: 1;
  }
}

.generate {
  box-shadow: 3px 3px 7px #bebebe, -3px -3px 7px #ffffff;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    margin: -2px -2px 0 0;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}
