@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 50px 24px;
  text-align: center;
  width: 100%;
}

.showPageText {
  @include fontSizeEm(12, 12);
  padding-bottom: 50px;
  
  div + div {
    margin-top: 1em;
  }
}

.text {
  @include fontSizeEm(12, 12);
  
  div + div {
    margin-top: 1em;
  }
}

.watermark {
  text-transform: uppercase;

  a {
    color: black;
    text-decoration: underline;
  }
}
