@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";
@import "assets/styles/abstracts/breakpoints";

$sizeUnit: 24px;

.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: space-between;
  align-items: center;
}

.img {
  width: 100%;
  height: 100%;
  padding: 60px 8px 0;
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.cropper {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
}

.footer {
  width: 100%;
  padding: 12px 8px;
  display: flex;
  justify-content: center;
  gap: 40px;
}

.circleIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cDark;
  border-radius: 100%;
  border: 2px solid $cDark;
  width: 50px;
  height: 50px;

  &.active {
    border: 2px solid transparent;
    background-image: linear-gradient($cDark, $cDark),
      linear-gradient(90deg, #f27c66, #fecd8f);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  &.active.disabled {
    border: 2px solid transparent;
    background-color: $gray400;
    background-image: linear-gradient($gray400, $gray400),
      linear-gradient(90deg, #f27c66, #fecd8f);
  }

  &.disabled {
    background-color: $gray400;
    border: 2px solid $gray50;
  }
}

.icon {
  display: flex;

  svg {
    display: block;
    width: 100%;
  }
}

.slider {
  width: 100%;
  padding: 60px 0 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.inner {
  position: relative;
}

.slides {
  flex: 1;
  padding: 0 30px;
  width: 100%;
  height: 100%;

  background-color: #f5f5f5;
  overflow-y: hidden;
  display: flex;
  align-items: stretch;
  overflow-x: hidden;
}

.slide {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  flex: 0 0 100%;
  padding: 0 4px;
  margin: 2px 0;
}

.media {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  &.text {
    padding: 16px 16px 100%;
  }

  &:not(.text) {
    align-items: center;
  }

  img {
    position: absolute;
    //background-color: $gray50;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    z-index: 1;
    left: 0;
  }

  &:after {
    content: "";
    border: 2px solid transparent;
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background-image: linear-gradient($gray50, $gray50),
      linear-gradient(180deg, #f27c66, #fecd8f);
    background-origin: border-box;
    background-clip: content-box, border-box;
    overflow: hidden;
    opacity: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.5s;
  }

  &:global(.active) {
    &:after {
      opacity: 1;
    }
  }
}

@keyframes animatedGradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.animatedGradient {
  background: linear-gradient(220deg, #f27c66, #fecd8f);
  background-size: 400% 400%;
  animation: animatedGradient 3s ease infinite;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.checkIcon {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: $cDark;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 1;

  span {
    display: flex;
    width: 12px;
  }

  svg {
    display: block;
    width: 100%;
  }
}

.dots {
  position: absolute;
  width: 100%;
  bottom: -20px;
  left: 0;
}

.body {
  width: 100%;
  padding: 47px 12px 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.prompt {
  width: 100%;
  flex: 1;

  form {
    display: grid;
    gap: 4px;
    grid-template-columns: 1fr 32px;
    align-items: end;
    height: 100%;
  }

  &.prompt__loading {
    pointer-events: none;
    opacity: 0.5;
  }
}

.prompt__submit {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: $cDark;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: flex;
    width: 10px;
    transform: rotate(-90deg);

    svg {
      display: block;
      width: 100%;
    }
  }

  &:disabled {
    background-color: $gray400;
  }
}
