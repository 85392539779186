#root {
  //height: 100dvh;
  min-height: calc(var(--vh, 1vh) * 100);

  @include desktop() {
    display: flex;
  }
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  
  @include mobile() {
    overscroll-behavior-y: none;
    overflow: hidden;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app {
  position: relative;
  height: 100dvh;
  max-height: 100dvh;
  max-width: 600px;
  margin: auto;
  overflow-x: hidden;

  @include desktop() {
    aspect-ratio: 9/19.5;
    border-radius: 2rem;
    max-height: 85vh;
    max-width: 100vw;
    min-width: 375px;
    min-height: 667px;
    overflow-y: hidden;
    transform: translateZ(0);

    &::before {
      border: 2px solid black;
      border-radius: 2rem;
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
    }
  }
}

.flex-column {
  display: flex;
  flex-direction: column;

  &.centered {
    justify-content: center;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;

  &.centered {
    justify-content: center;
  }
}

.btn-container {
  margin-top: 16px;
}

.btn-container__paired {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.btn {
  width: 100%;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 12px 16px;
}

.btn-primary {
  background-color: $cDark;
  color: $white;
  padding: 12px 16px;
}

.btn-secondary {
  background-color: transparent;
  color: $cDark;
  padding: 12px 16px;
  border: 1px solid $cDark;
}

.reactEasyCrop_Container {
  bottom: 80px!important;
}
