.st1 {
  @include fontSize(48, 56);
  font-weight: 600;

  .line {
    overflow: hidden;
    line-height: 1.4;

    &+.line {
      margin-top: -18px;
    }

    .char {
      transform: translateY(101%)
    }
  }
}

.st2 {
  @include fontSize(32, 40);
  font-weight: 600;
  margin-bottom: 32px;
}

.sp0 {
  @include fontSize(18, 24);
}

.sp1 {
  @include fontSize(14, 18);
}

.sp2 {
  @include fontSize(12, 16);

  a {
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.sp3 {
  @include fontSize(10, 14);
}

.font-semibold {
  font-weight: 600;
}
