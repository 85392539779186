@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.icon {
  min-height: 28px;
  display: flex;

  svg {
    width: 100%;
    height: auto;
  }
}

.heading {
  // background-color: $white;
  border-bottom: 1px solid lightgrey;
  border-radius: 1rem 1rem 0 0;
  padding: 8px 8px 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}

.action {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.levelIsActive {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.close,
.confirm {
  width: 32px;
  height: 32px;
}

.close {
  cursor: pointer;
  padding: 11.5px;
}

.confirm {
  padding: 9px;
  border: 1px solid $cDark;
  border-radius: 100%;
}

.back {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
}

.chevron {
  width: 6px;
  transform: rotate(180deg);
}
