@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  display: flex;
  width: 100%;
  aspect-ratio: $projectSectionRatio;
}

.image {
  position: absolute;
  opacity: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: clip-path 0.5s $ExpoEaseInOut;

  img {
    position: absolute;
    object-fit: cover;
    transition: all 0.5s $ExpoEaseInOut;
  }
}

.heading {
  @include fontSizeEm(32, 32);
  font-family: 'AtiplaND';
  text-transform: uppercase;
  position: absolute;
  top: 32px;
  left: 24px;
}

.text {
  @include fontSizeEm(16, 20);
  text-transform: uppercase;
  text-align: left;
  padding: 0 50px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 72%;
  text-align: justify;
}

.triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 0 10px 17.3px;
  border-color: transparent transparent transparent $gray50;
  transition: all 0.5s $ExpoEaseInOut;
}

.content[data-layout='1'] {
  color: $gray50;

  .image {
    clip-path: inset(0 0 0 0);

    img {
      width: 100%;
      height: 100%;
      top: 0%;
      left: 0;
    }
  }
}

.content[data-layout='2'] {
  .image {
    clip-path: inset(33.3% 0 33.3% 0);

    img {
      width: 100%;
      height: 33.3%;
      top: 33.3%;
      left: 0;
    }
  }
}

.content[data-layout='3'] {
  .image {
    clip-path: inset(16.9% 21.86% 32.75% 21.86%);

    img {
      width: 56.28%;
      height: 50.35%;
      top: 16.9%;
      left: 21.86%;
    }
  }

  .triangle {
    top: 42.07%;
  }
}
