@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  background: #232323;
  color: white;
  display: flex;
  align-items: center;
  width: 100%;
  height: 215px;
  transition: background-color 0.2s ease-in-out, color  0.2s ease-in-out;
}

.text {
  font-family: 'AtiplaND';
  margin-left: 24px;
  text-align: justify;
  text-transform: uppercase;
  transition: all 0.3s $ExpoEaseInOut;
  width: 70%;

  &::before {
    content: '“';
  }

  &::after {
    content: '”';
  }
}

.content[data-layout='1'],
.content[data-layout='2'] {
  .text {
    @include fontSizeEm(16, 20);
  }
}

.content[data-layout='2'],
.content[data-layout='3'] {
  .text {
    margin-left: 15%;
  }
}

.content[data-layout='2'] {
  .text {
    position: relative;

    &::before,
    &::after {
      font-size: 12.6vw;
      position: absolute;
    }

    &::before {
      top: -0.5em;
      right: 100%;
    }

    &::after {
      bottom: -0.9em;
      left: 100%;
    }
  }
}

.content[data-layout='3'] {
  .text {
    font-family: 'AtiplaND';
    @include fontSizeEm(24, 24);
    text-align: center;
  }
}
