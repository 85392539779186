@font-face {
  font-family: 'AtiplaND';
  src: url(../../fonts/AtiplaND-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
    font-family: 'Roobert';
    src: url(../../fonts/Roobert-Regular.woff2) format('woff2'),
        url(../../fonts/Roobert-Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Roobert';
    src: url(../../fonts/Roobert-SemiBold.woff2) format('woff2'),
        url(../../fonts/Roobert-SemiBold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Haiku-400';
    src: url(../../fonts/Haiku-400.woff2) format('woff2'),
        url(../../fonts/Haiku-400.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}
