@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  aspect-ratio: $projectSectionRatio;
}

.main {
  padding: 126px 24px 46px;
  transition: all 0.5s $ExpoEaseInOut;
}

.heading {
  @include fontSizeEm(32, 32);
  font-family: 'AtiplaND';
  text-transform: uppercase;
  padding-bottom: 66px;
  position: absolute;
}

.subHeading {
  @include fontSizeEm(16, 20);
  font-family: 'AtiplaND';
  text-transform: uppercase;
  padding-bottom: 12px;
}

.list {
  @include fontSizeEm(16, 20);
  text-transform: uppercase;
  margin-bottom: 33px;
  transition: all 0.5s $ExpoEaseInOut;
}

.content {
  &[data-layout='1'],
  &[data-layout='3'] {
    .heading {
      padding: 50px 24px;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &[data-layout='1'] {
    .heading {
      text-align: left;
    }
  }

  &[data-layout='2'] {
    .main {
      padding-left: 72px;
    }

    .heading {
      text-align: right;
      top: 24px;
      right: calc(100% - 24px);
      width: 100%;
      transform: rotate(-90deg);
      transform-origin: top right;
    }
  }

  &[data-layout='3'] {
    text-align: right;

    .heading {
      text-align: right;
    }
  }
}
