@import 'assets/styles/abstracts/variables';

.loadingRing {
  border-radius: 100%;
  box-shadow: inset 0px 0px 95px #f4f4f4;
  opacity: 1;
  overflow: hidden;
  position: relative;
  transition: 0.25s;
  width: 100%;
}

.loadingRingProgress {
  display: inline-block;
  height: 100%;
  padding: 40px 40px;
  transform: rotate(-90deg);
  width: 100%;
}

.loadingRingSpacer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.loadingRingValue {
  font-weight: 600;
  position: absolute;
  text-align: center;
  top: 5px;
  width: 100%;
  z-index: 1;
}

.orbVideo {
  border-radius: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  opacity: 1;
  position: relative;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;

  @keyframes pulseOpacity {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

  -webkit-animation: pulseOpacity 2.5s ease-out;
  -webkit-animation-iteration-count: infinite;
}

.orbVideoContainer {
  border-radius: 100%;
  box-shadow: inset 0px 0px 65px #f4f4f4;
  height: 100%;
  overflow: hidden;
  padding: 40px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
