@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  background: white;
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  padding: 16px;
  position: relative;
  width: 100%;
  z-index: 2;
}

.action {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }
}
