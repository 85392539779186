@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.twoFactorForm {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.twoFactorInput {
  display: inline-flex;
  width: 15%;
  @include fontSize(56, 56);
  border-bottom: 1px solid $gray200;
  text-align: center;
  color: $cDark;

  &::placeholder {
    color: $gray200;
  }
}

.actions {
  display: flex;
  justify-content: center;
  margin: 88px 0 38px;
}

.ctas {
  position: relative;
}

.back {
  position: absolute;
  top: 0;
  right: 100%;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 1px solid $cDark;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;

  span {
    display: flex;
    width: 10px;
    transform: rotate(180deg);
  }

  &:hover {
    @include desktop() {
        
    }
  }
}