@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 24px;
  }

.line {
    display: block;
    height: 0;
}

.content[data-layout='1'],
.content[data-layout='2'] {
    .line {
        width: 100%;
    }
}

.content[data-layout='1'] {
    .line {
        border-top: 1px solid $cDark;
    }
}

.content[data-layout='2'] {
    .line {
        width: 100%;
        border-top: 1px dashed $cDark;
    }
}

.content[data-layout='3'] {
    .line {
        width: 70%;
        border-top: 2px solid $cDark;
    }
}