.p-results {
  .page_content {
    position: relative;
    padding: 0;
  }

  .results {
    &_header {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 32px;
      padding-right: 32px;

      .button {
        height: 40px;
        width: calc(50% - 8px);

        &.active {
          outline: 1px solid $cDarker;
          background-color: $cDarker;
          color: #FFF;
        }
      }
    }

    &_regen {
      position: fixed;
      z-index: 10;
      bottom: 8px;
      right: 8px;
    }

    &_content {
      overflow-y: scroll;
      scroll-snap-stop: always;
      scroll-snap-type: y mandatory;
      height: calc(100dvh - 60px - 60px);
      padding-bottom: 32px;
    }

    &_row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scroll-snap-align: center;
      scroll-snap-stop: always;
      scroll-snap-type: x mandatory;
      scrollbar-color: transparent transparent;
      scrollbar-width: thin;
      padding-left: 32px;
      padding-right: 32px;

      &+.results_row {
        margin-top: 8px;

        scrollbar-color: transparent transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
          height: 0rem;
          width: 0rem;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
        }
      }

      &_item {
        position: relative;
        background-color: #EEE;
        // min-width: calc(100vw - 32px - 32px);
        // width: calc(100vw - 32px - 32px);
        width: min(20rem, 80vw);
        scroll-snap-align: center;

        &::before {
          content: "";
          display: block;
          padding-top: 155%;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // width: 120%; height: 120%;
          // transform: scale(0.833333); // 1 / 1.2
          // transform-origin: top left;
        }
      }
    }
  }
}







.EditExperimentPage {
  height: 100%;
  overflow-y: scroll;
  scroll-snap-stop: always;
  scroll-snap-type: y mandatory;
    
  &::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .visible-options-window {
    height: 100dvh;
    margin: auto;
    overflow: scroll;
    padding-bottom: 2dvh;
    padding-top: calc($navBarHeight + 8px);
    position: absolute;
    scroll-margin: 1px;
    scroll-snap-stop: always;
    scroll-snap-type: y mandatory;
    top: 0;
    transform: translateY(0);
    transition: 1s $ExpoEaseOut;
    width: 100%;
    will-change: top;
    
    &::-webkit-scrollbar {
      height: 0px;
      width: 0px;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &.loading {
      transform: translateY(100vh);
    }

    -webkit-mask-image: linear-gradient(
      to right, /* gradient direction */
      rgba(0,0,0,0) 0%, /* transparent left 3% */
      rgba(0,0,0,1) 5%, /* visible in the middle start */
      rgba(0,0,0,1) 95%, /* visible in the middle end */ 
      rgba(0,0,0,0) 100% /* transparent right 3% */ 
    );

    .OptionsRow {
      padding: 0 calc((100% - $page-section-option-width) / 2);
      scroll-snap-align: center;
      scroll-snap-stop: always;
    
      &::-webkit-scrollbar {
        height: 0px;
        width: 0px;
      }
    
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      .page-option-scale-container {
        height: calc(min(100vh, $iPhone15Height) * $page-option-scale-factor);
        margin: 0.5rem 0.5rem;
        min-width: calc(min(100vw, $iPhone15Width) * $page-option-scale-factor);
        overflow: hidden;
        scroll-snap-align: center;

        &.navBar {
          height: calc((69px + 2px) * $page-option-scale-factor);
        }

        &.quote {
          height: calc((min(100vh, $iPhone15Height) / 2) * $page-option-scale-factor);
        }

        &.score {
          height: calc((min(100vh, $iPhone15Height) / 2) * $page-option-scale-factor);
        }

        .page-option-preview {
          min-width: min(100vw, $iPhone15Width);
          transform: scale($page-option-scale-factor);
          transform-origin: top left;

          &.about {
            height: min(100vh, $iPhone15Height);
          }
          
          &.contact {
            height: min(100vh, $iPhone15Height);
          }

          &.gallery {
            height: min(100vh, $iPhone15Height);
          }

          &.home {
            height: min(100vh, $iPhone15Height);
          }

          &.navBar {
            outline: 1px solid $cDark;
            height: 69px;
          }

          &.products {
            height: min(100vh, $iPhone15Height);
          }

          &.quote {
            height: min(50vh, calc($iPhone15Height / 2));
          }

          &.score {
            height: min(50vh, calc($iPhone15Height / 2));
          }
        }
      }
    }

    // deal with scrolling of all option rows
    >* {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      scrollbar-color: transparent transparent;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        height: 0rem;
        width: 0rem;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      
      .page-option-preview {
        border: none;
        scroll-snap-align: center;
      }
    }
  }
}


.ShowExperimentPage {
  background: #fff;
  display: block;
  height: 100vh;
  overflow: scroll;
  scroll-snap-stop: always;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    height: 0rem;
    width: 0rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .page-option-preview {
    margin-bottom: -4px;
    scroll-snap-align: start;
    scroll-snap-stop: always;

    &.full-site {
      height: 100dvh;
    }

    &.about {
      height: 100dvh;
    }
    
    &.contact {
      height: 100dvh;
    }

    &.gallery {
      height: 100dvh;
    }

    &.home {
      height: 100dvh;
    }

    &.navBar {
      bottom: 0;
      height: 69px;
      position: fixed;
      width: 100%;
    }

    &.products {
      height: 100dvh;
    }

    &.quote {
      height: max(24dvh, 300px);
    }

    &.score {
      height: max(24dvh, 300px);
    }
  }
}
