@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  cursor: pointer;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  text-align: center;
  width: 146px;
  height: 344px;
  background-color: $white;
  border-radius: 8px;
  padding: 16px;
  margin: 0 auto;
}

.title {
  @include fontSize(24, 24);
}

.icon {
  display: flex;
  height: auto;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}
