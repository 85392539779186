@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";
@import "assets/styles/abstracts/breakpoints";

.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - $navBarHeight);
  z-index: 20;
  background-color: rgba(47, 47, 47, 0.8);

  @include desktop() {
    height: 100%;
    top: 0;
  }
}

.container {
  position: absolute;
  top: 16px;
  left: 16px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  background-color: $gray50;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.close {
  width: 13px;
  height: 13px;
  padding: 8px;
  box-sizing: content-box;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;

  svg {
    display: block;
    width: 100%;
  }
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.footer {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 24px;
  border-radius: 0 0 8px 8px;

  & > div {
    width: 60vw;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
