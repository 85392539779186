video {
  &.hidden-preloader {
    display: none;
  }
}

#video-background-container {
  overflow: hidden;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.4s;
  width: 100%;
  z-index: -1;

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  video {
    bottom: 0;
    height: auto;
    left: -20%;
    margin: auto;
    position: absolute;
    right: 0;
    transform: scale(1);
    transition: transform 0.4s;
    top: 0;
    width: 140%;

    &#orb-intro {
      animation-duration: 0.75s;
      animation-direction: normal;
      animation-name: fade-in;
      animation-timing-function: $CircEaseInOut;

      opacity: 1;
      z-index: -2;
    }

    &#orb-loop {
      opacity: 0;
      transition: opacity 1s $CircEaseInOut;
      z-index: -3;

      &.loaded {
        opacity: 1;
      }
    }
  }

  /* &.intro-ended {
    video#orb-intro {
      opacity: 0;
    }

    video#orb-loop {
      opacity: 1;
    }
  } */

  &.field-focused {
    video {
      filter: blur(6px);
      transform: scale(1.6);
    }
  }

  &.form-submitted {
    opacity: 0;
    transform: scale(10);
  }

  &.minimal {
    video {
      filter: grayscale(1);
    }
  }

  &.pulsing:not(.field-focused) {
    video {
      transform: scale(0.9);
    }
  }
}
