@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $cDark;
  color: $white;
  padding: 12px 16px;
  z-index: 1;
  position: relative;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.square {
  @include fontSize(12, 12);
  font-weight: 600;
  flex-direction: column;
  border-radius: 8px;
  gap: 4px;
}

.rounded {
  @include fontSize(16, 16);
  justify-content: center;
  gap: 8px;
  border-radius: 9999px;
}

.icon {
  display: flex;
  width: 16px;

  svg {
      width: 100%;
      height: auto;
  }
}
