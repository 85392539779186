@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  display: flex;
  width: 100%;
  aspect-ratio: $projectSectionRatio;
}

.image {
  position: absolute;
  opacity: 1;
  width: 100%;
  height: 100%;
  transition: clip-path 0.5s $ExpoEaseInOut;

  img {
    object-fit: cover;
    position: absolute;
    transition: all 0.5s $ExpoEaseInOut;
  }
}

////////////////////////////////
// ONE IMAGE ///////////////////
////////////////////////////////

.content[data-count='1'] {
  .image {
    top: 0;
    left: 0;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

////////////////////////////////
// TWO IMAGES //////////////////
////////////////////////////////

.content[data-count='2'] {
  .image {
    top: 0;
    left: 0;

    img {
      width: 100%;
      left: 0;
    }
  }

  &[data-layout='1'] {
    .image {
      img {
        height: 50%;
      }

      &:nth-child(1) {
        clip-path: inset(0 0 50% 0);

        img {
          top: 0;
        }
      }

      &:nth-child(2) {
        clip-path: inset(50% 0 0 0);

        img {
          top: 50%;
        }
      }
    }
  }

  &[data-layout='2'] {
    .image {
      &:nth-child(1) {
        clip-path: inset(0 0 33.3% 0);

        img {
          height: 66.6%;
          top: 0;
        }
      }

      &:nth-child(2) {
        clip-path: inset(66.6% 0 0 0);

        img {
          height: 33.3%;
          top: 66.6%;
        }
      }
    }
  }

  &[data-layout='3'] {
    .image {
      &:nth-child(1) {
        clip-path: inset(0 0 66.6% 0);

        img {
          top: 0;
          height: 33.3%;
        }
      }

      &:nth-child(2) {
        clip-path: inset(33.3% 0 0 0);

        img {
          top: 33.3%;
          height: 66.6%;
        }
      }
    }
  }
}

////////////////////////////////
// THREE IMAGES ////////////////
////////////////////////////////

.content[data-count='3'] {
  &[data-layout='1'] {
    .image {
      &:nth-child(1) {
        clip-path: inset(0 50% 66.6% 0);

        img {
          width: 50%;
          height: 33.3%;
          top: 0;
          left: 0;
        }
      }

      &:nth-child(2) {
        clip-path: inset(0 0 66.6% 50%);

        img {
          width: 50%;
          height: 33.3%;
          top: 0;
          left: 50%;
        }
      }

      &:nth-child(3) {
        clip-path: inset(33.3% 0 0 0);

        img {
          width: 100%;
          height: 66.6%;
          top: 33.3%;
          left: 0;
        }
      }
    }
  }

  &[data-layout='2'] {
    .image {
      &:nth-child(1) {
        clip-path: inset(0 0 33.3% 0);

        img {
          width: 100%;
          height: 66.6%;
          top: 0;
          left: 0;
        }
      }

      &:nth-child(2) {
        clip-path: inset(66.6% 50% 0 0);

        img {
          width: 50%;
          height: 33.3%;
          top: 66.6%;
          left: 0;
        }
      }

      &:nth-child(3) {
        clip-path: inset(66.6% 0 0 50%);

        img {
          width: 50%;
          height: 33.3%;
          top: 66.6%;
          left: 50%;
        }
      }
    }
  }

  &[data-layout='3'] {
    .image {
      img {
        width: 100%;
        height: 33.3%;
        left: 0;
      }

      &:nth-child(1) {
        clip-path: inset(0 0 66.6% 0);

        img {
          top: 0;
        }
      }

      &:nth-child(2) {
        clip-path: inset(33.3% 0 33.3% 0);

        img {
          top: 33.3%;
        }
      }

      &:nth-child(3) {
        clip-path: inset(66.6% 0 0 0);

        img {
          top: 66.6%;
        }
      }
    }
  }
}

////////////////////////////////
// FOUR IMAGES /////////////////
////////////////////////////////

.content[data-count='4'] {
  &[data-layout='1'] {
    .image {
      img {
        width: 50%;
        height: 50%;
      }

      &:nth-child(1) {
        clip-path: inset(0 50% 50% 0);

        img {
          top: 0;
          left: 0;
        }
      }

      &:nth-child(2) {
        clip-path: inset(0 0 50% 50%);

        img {
          top: 0;
          left: 50%;
        }
      }

      &:nth-child(3) {
        clip-path: inset(50% 50% 0 0);

        img {
          top: 50%;
          left: 0;
        }
      }

      &:nth-child(4) {
        clip-path: inset(50% 0 0 50%);

        img {
          top: 50%;
          left: 50%;
        }
      }
    }
  }

  &[data-layout='2'] {
    .image {
      &:nth-child(1),
      &:nth-child(2) {
        img {
          width: 50%;
          height: 50%;
          top: 0;
        }
      }

      &:nth-child(3),
      &:nth-child(4) {
        img {
          width: 100%;
          height: 25%;
          left: 0;
        }
      }

      &:nth-child(1) {
        clip-path: inset(0 50% 50% 0);

        img {
          left: 0;
        }
      }

      &:nth-child(2) {
        clip-path: inset(0 0 50% 50%);

        img {
          left: 50%;
        }
      }

      &:nth-child(3) {
        clip-path: inset(50% 0 25% 0);

        img {
          top: 50%;
        }
      }

      &:nth-child(4) {
        clip-path: inset(75% 0 0 0);

        img {
          top: 75%;
        }
      }
    }
  }

  &[data-layout='3'] {
    .image {
      img {
        height: 33.3%;
      }

      &:nth-child(1),
      &:nth-child(4) {
        img {
          width: 100%;
          left: 0;
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        img {
          width: 50%;
          top: 33.3%;
        }
      }

      &:nth-child(1) {
        clip-path: inset(0 0 66.6% 0);

        img {
          top: 0;
        }
      }

      &:nth-child(2) {
        clip-path: inset(33.3% 50% 33.3% 0);

        img {
          left: 0;
        }
      }

      &:nth-child(3) {
        clip-path: inset(33.3% 0 33.3% 50%);

        img {
          left: 50%;
        }
      }

      &:nth-child(4) {
        clip-path: inset(66.6% 0 0 0);

        img {
          top: 66.6%;
        }
      }
    }
  }
}

////////////////////////////////
// FIVE IMAGES /////////////////
////////////////////////////////

.content[data-count='5'] {
  &[data-layout='1'] {
    .image {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        img {
          width: 50%;
          height: 33.33%;
        }
      }

      &:nth-child(1) {
        clip-path: inset(0 50% 66.6% 0);

        img {
          top: 0;
          left: 0;
        }
      }

      &:nth-child(2) {
        clip-path: inset(0 0 66.6% 50%);

        img {
          top: 0;
          left: 50%;
        }
      }

      &:nth-child(3) {
        clip-path: inset(33.33% 50% 0 0);

        img {
          top: 33.33%;
          left: 0;
        }
      }

      &:nth-child(4) {
        clip-path: inset(33.33% 0 0 50%);

        img {
          top: 33.33%;
          left: 50%;
        }
      }

      &:nth-child(5) {
        clip-path: inset(66.66% 0 0 0);

        img {
          top: 66.66%;
          left: 0;
        }
      }
    }
  }

  &[data-layout='2'] {
    .image {
      &:nth-child(2),
      &:nth-child(5) {
        img {
          width: 50%;
          height: 44.45%;
        }
      }

      &:nth-child(3),
      &:nth-child(4) {
        img {
          width: 50%;
          height: 22.22%;
        }
      }

      &:nth-child(1) {
        clip-path: inset(0 0 66.66% 0);

        img {
          width: 100%;
          height: 33.33%;
          top: 0;
          left: 0;
        }
      }

      &:nth-child(2) {
        clip-path: inset(33.33% 50% 22.22% 0);

        img {
          top: 33.33%;
          left: 0;
        }
      }

      &:nth-child(3) {
        clip-path: inset(33.33% 0 44.45% 50%);

        img {
          top: 33.33%;
          left: 50%;
        }
      }

      &:nth-child(4) {
        clip-path: inset(77.78% 50% 0 0);

        img {
          top: 77.78%;
        }
      }

      &:nth-child(5) {
        clip-path: inset(44.45% 0 0 50%);

        img {
          top: 55.55%;
          left: 50%;
        }
      }
    }
  }

  &[data-layout='3'] {
    .image {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        img {
          width: 50%;
          height: 25%;
        }
      }

      &:nth-child(1) {
        clip-path: inset(0 50% 75% 0);

        img {
          top: 0;
          left: 0;
        }
      }

      &:nth-child(2) {
        clip-path: inset(0 0 75% 50%);

        img {
          top: 0;
          left: 50%;
        }
      }

      &:nth-child(3) {
        clip-path: inset(25% 0 25% 0);

        img {
          width: 100%;
          height: 50%;
          top: 25%;
          left: 0;
        }
      }

      &:nth-child(4) {
        clip-path: inset(75% 50% 0 0);

        img {
          top: 75%;
          left: 0;
        }
      }

      &:nth-child(5) {
        clip-path: inset(75% 0 0 50%);

        img {
          top: 75%;
          left: 50%;
        }
      }
    }
  }
}
