@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

$project-item-scale-factor: 0.66;

.container {
  background: $white;
  // margin: auto auto 16px auto;
  max-height: calc(100vh - 80px - 78px);
  // max-width: 75%;
  // border-radius: 16px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  // max-height: 60vh;
  max-height: calc(100vh - 80px - 78px);
  width: 100%;
}

.iframeContainer {
  width: 100%;
  aspect-ratio: $projectIframeRatio;
  overflow: hidden;
}

.iframeAligner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 152%;
  height: 152%;
  
  iframe {
    width: 100%;
    aspect-ratio: $projectIframeRatio;
    transform: scale($project-item-scale-factor);
    transform-origin: top left;
  }
}

.title {
  padding: 16px 12px;
}

.actions {
  padding: 16px 16px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
