.visually-hidden {
  position: absolute;
  left: 0;
  top: -500px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.shadow-sm {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
}

.shadow-md {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.bg-blur {
  backdrop-filter: blur(10px);
}

.bg-gradient {
  background: linear-gradient(90deg, #f27c66 0%, #fecd8f 100%);
}

.custom-scrollbar {
  @include desktop() {
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    @include desktop() {
      width: 0;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    @include desktop() {
      background-color: transparent;
      border: none;
    }
  }
}

.circled-ol {
  counter-reset: custom-counter;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;

  li {
    counter-increment: custom-counter;
    display: flex;
    gap: 16px;

    &:before {
      content: counter(custom-counter);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      font-weight: 600;
      border: 1px solid $cDark;
      border-radius: 100%;
    }
  }
}

.border-gradient {
  background-image: linear-gradient($white, $white),
    linear-gradient(90deg, #f27c66, #fecd8f);
  background-origin: border-box;
  background-clip: content-box, border-box;
  overflow: hidden;
}

.border-gradient-gray50 {
  background-image: linear-gradient($gray50, $gray50),
    linear-gradient(90deg, #f27c66, #fecd8f);
  background-origin: border-box;
  background-clip: content-box, border-box;
  overflow: hidden;

  &.cta {
    box-shadow:  3px 3px 7px #bebebe, -3px -3px 7px #ffffff;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 9999px;
    color: $cDark;
    cursor: pointer;
    @include fontSize(18, 24);
    
    .text {
      padding: 10px;
      position: relative;
    }
  }
}

.hover-gradient {
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-image: linear-gradient(90deg, #f27c66, #fecd8f);
    opacity: 0;
    transition: all 0.3s $ExpoEaseOut;
  }

  &:hover {
    &:before {
      @include desktop() {
        opacity: 1;
      }
    }
  }
}

.underline-custom-inline {
  display: inline;
  background-image: linear-gradient(to bottom, $cDark 50%, $cDark 50%);
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: left bottom 0.15em;
}

.underline-custom {
  display: inline-block;
  text-decoration: none;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    bottom: -0.15em;
    padding-top: 1px;
    background-color: $cDark;
  }
}

.transition-all {
  transition: all 0.3s $ExpoEaseOut;
}
