@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - $navBarHeight);
  z-index: 20;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.body {
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  margin: 16px;
  z-index: 10;

  h3 {
    @include fontSize(16, 24);
    font-weight: 600;
    color: $cDarker;
    margin-bottom: 16px;
  }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
}

.close {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 10px;
    right: 16px;
}