@import 'assets/styles/abstracts/variables';
$project-item-scale-factor: 0.42;

.container {
  position: relative;
  width: 100%;
  aspect-ratio: $projectIframeRatio;
  overflow: hidden;
  border-radius: 1rem;

  .clickableOverlay {
    background-color: transparent;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

.aligner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 238%;
  height: 238%;

  iframe {
    width: 100%;
    aspect-ratio: $projectIframeRatio;
    transform: scale($project-item-scale-factor);
    transform-origin: top left;
  }
}
