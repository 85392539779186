@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  @include fontSize(24, 32);
  font-weight: 600;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  ul {
    li {
      border-radius: 1rem;
      padding: 0.5rem 1rem;

      & + li {
        margin-top: 8px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        text-decoration: none;
      }
    }
  }

  span {
    display: flex;
    width: 8px;
    margin-right: 8px;

    svg {
      width: 100%;
      height: auto;
    }
  }
}
