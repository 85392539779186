@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 33px 24px 46px;
  transition: all 0.5s $ExpoEaseInOut;
  width: 100%;
}

.heading {
  @include fontSizeEm(24, 24);
  font-family: 'AtiplaND';
  text-transform: uppercase;
  padding-bottom: 24px;
}

.text {
  @include fontSizeEm(16, 20);
  text-transform: uppercase;
  width: 100%;
  text-align: justify;
}

.content[data-layout='1'] {
  .heading {
    text-align: left;
  }
}

.content[data-layout='2'] {
  .heading {
    text-align: right;
  }
}

.content[data-layout='3'] {
  .heading {
    text-align: center;
  }
}
