@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  padding: 16px 16px 80px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

///////////////////////////////////
// HEADER /////////////////////////
///////////////////////////////////

.header__container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.header__back {
  display: flex;
  align-items: center;
  gap: 6px;
  position: absolute;
  top: 0;
  left: 0;

  span {
    display: inline-block;
    width: 8px;

    svg {
      display: block;
      width: 100%;
      transform: rotate(180deg);
    }
  }
}

.header__avatar {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.header__heading {
  margin-bottom: 8px;
}

.header__description {
  color: $gray400;
  margin-bottom: 16px;
}

a.header__cta {
  font-size: 12px;
  font-weight: 600;
  background-color: $cDark;
  border: 1px solid $cDark;
  color: $white;
  padding: 13px 16px;
  border-radius: 8px;
  min-width: 146px;

  &:hover {
    @include desktop() {
        background-color: transparent;
        text-decoration: none;
        color: $cDark;
    }
  }
}

///////////////////////////////////
// SPACE LIST ITEM ////////////////
///////////////////////////////////

.spaceListItem__container {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid $gray200;
  padding: 4px 0;
  position: relative;
}

.spaceListItem__icon {
  position: absolute;
  top: 8px;
  right: 0;
  width: 9px;
  display: inline-block;
  width: 8px;

  svg {
    display: block;
    width: 100%;
  }
}

.spaceListItem__img {
  width: 44px;
  height: 72px;
  border-radius: 4px;
  overflow: hidden;
  flex: 0 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.spaceListItem__placeholder {
  width: 44px;
  height: 72px;
  border-radius: 4px;
  background-color: $gray200;
  flex: 0 0 auto;
}

.spaceListItem__text {
    margin-top: -2px;
}

.spaceListItem__heading {
  margin-bottom: 4px;
}

.spaceListItem__info {
  color: $gray400;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
