@import 'assets/styles/abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(220px + 16px);
  width: calc(220px + 16px);
  padding-right: 16px;
}

.card {
  background-color: $white;
  padding: 4px;
  border-radius: 1rem;
  // min-height: 347px;
}
