.container {
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 1rem #00000047;
  width: 100%;
  // border: 1px solid black;

  &[data-layout='2'] {
    .contentAlign {
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }

  &[data-layout='3'] {
    .contentAlign {
      justify-content: flex-end;
      flex-direction: row-reverse;
    }
  }
}

.contentAlign {
  display: flex;
  gap: 20px;
  padding: 20px 24px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.section {
  margin-bottom: 16px;
  position: relative;
}

.showSection {
  background: rgba(255, 255, 255, 0.56);
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 100;

  .container {
    border: none;
    margin: auto;
  }
}

.menuIcon {
  width: 26px;
  height: 6px;
  position: relative;

  span {
    display: inline-block;
    background-color: black;
    height: 1px;
    position: absolute;
    left: 0;
    transition: 0.25s;

    &:nth-child(1) {
      width: 26px;
      top: 0;
    }

    &:nth-child(2) {
      width: 20px;
      bottom: 0;
    }
  }
}

.menuContents {
  background: rgba(255, 255, 255, 0.56);
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: 0.25s;
  width: 100%;
}

.menuOpen {
  .menuContents {
    height: 50px;
    top: -50px;
  }

  .menuIcon {
    span {
      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        width: 26px;
        transform: rotate(-45deg);
      }
    }
  }
}
