@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {
    display: flex;
    flex-direction: column;
    padding: 0 4px;
    white-space: normal;
}

.heading {
    margin-bottom: 12px;
}

.main {
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.status {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    border: 2px solid $gray50;
    border-radius: 4px;
    background-color: $gray50;

    &.active {
        background-color: $white;
    }
}