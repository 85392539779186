@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.actions {
  position: fixed;
  right: 10px;
  top: 30px;
}

.close {
  margin-bottom: 24px;
}

.wrapper {
  display: flex;
  justify-content: center;
  
  @include desktop() {
    flex: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.container {
  width: 100%;
  zoom: 1.15;
}

.ctaSave {
  cursor: pointer;
  margin-bottom: 32px;
}
