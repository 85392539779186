@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.heart {
    display: flex;
    align-items: center;
    margin-top: 1px;
}