@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: $cDark;
  padding: 12px 16px;
  z-index: 1;
  position: relative;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.square {
  @include fontSize(12, 12);
  font-weight: 600;
  flex-direction: column;
  border-radius: 8px;
  gap: 4px;
}

.rounded {
  @include fontSize(16, 16);
  justify-content: center;
  gap: 8px;
  border-radius: 9999px;
}

.icon {
  display: flex;

  svg {
      width: 100%;
      height: auto;
  }
}

.iconButton {
  height: 2.5rem;
  width: 2.5rem;
}

.iconWithText {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  gap: 0.25rem;
  justify-content: center;
}

.updatedRounded {
  border-radius: 100%;
  box-shadow:  3px 3px 7px #bebebe, -3px -3px 7px #ffffff;
  color: $cDark;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  padding: 0.5rem;
}
