@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";
@import "assets/styles/abstracts/breakpoints";

.card {
  // background-color: $white;
  box-shadow: inset 3px 3px 7px #cecece, inset -3px -3px 7px #ffffff;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 1rem;
  position: relative;
}

.hasIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.icon {
  width: 16px;
  height: 16px;
}

.counter {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.isMultipleLines {
  padding-bottom: 28px;
}

.buttonContainer {
  display: flex;
  justify-content: center;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.button {
  color: white;
  background-color: $cDark;
  border-radius: 12px;
  padding: 16px 32px;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
}

.button,
.buttonContainer {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
