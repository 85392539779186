.imageRender{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    overflow: hidden;

    &_cover{
        position: absolute;
        top: -1px; left: -1px; right: -1px; bottom: -1px;
        opacity: 0;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        will-change: transform, opacity;
        transition: opacity 0.3s linear, transform 0.8s $ExpoEaseOut;
    }

    &--loaded{
        .imageRender_cover{
            opacity: 1;
        }
    }
}