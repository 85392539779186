@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  -webkit-overflow-scrolling: touch;
  background-color: $video-background-color;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: auto;
  padding: calc($navBarHeight + 16px) 16px 72px 16px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(101%);
  transition: transform 0.8s $ExpoEaseOut;
  will-change: transform;
  z-index: 10;

  @include desktop() {
    padding: calc($navBarHeightLg + 16px) 16px 72px 16px;
  }
}

.visible {
  transform: translateX(0);
}

.main {
  flex: 1;
}

.heading {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(60px, 1fr) 2fr minmax(60px, 1fr);
  margin-bottom: 32px;

  button {
    color: $cDark;
  }
}

.left {
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-top: 1px solid $cDark;
    border-left: 1px solid $cDark;
    transform: rotate(-45deg);
    transform-origin: bottom;
    margin: 0 5px 0 4px;
  }
}

.right {
  text-align: right;
}

.title {
  @include fontSize(16, 24);
  font-weight: 600;
  color: $cDarker;
  grid-column: auto;
  text-align: center;
}

.avatarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  position: relative;
}

.avatar {
  width: 112px;
  height: 112px;
  border-radius: 100%;
  background-color: #ccc;
  border-radius: 100%;
  overflow: hidden;
}

.avatar__edit {
  position: absolute;
  top: 0;
  left: 0;
  color: $white;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 8px 0;
}

.actions {
  position: fixed;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 32px 16px 32px;
  text-align: center;
}

.ctaGroup {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
}

.footer {
  color: $gray350;

  a {
    text-decoration: underline;
  }
}

.box {
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
}

.inviteCta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 14px 16px;
  color: $white;
  border-radius: 8px;
  margin-bottom: 0;
  border: 1px solid $gray50;

  span {
    width: 14px;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
  }

  &:disabled {
    background-color: transparent;
    border: 1px solid $gray350;
    color: $gray350;
  }
}
