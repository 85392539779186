@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  display: flex;
  width: 100%;
  aspect-ratio: $projectSectionRatio;
}

.image {
  position: absolute;
  opacity: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: clip-path 0.5s $ExpoEaseInOut;

  img {
    position: absolute;
    object-fit: cover;
    transition: all 0.5s $ExpoEaseInOut;
  }
}

.heading {
  @include fontSizeEm(32, 32);
  font-family: 'AtiplaND';
  text-transform: uppercase;
  position: absolute;

  span {
    width: 100%;
    display: block;
    transform: rotate(0);
    transform-origin: top right;
  }
}

.text {
  @include fontSizeEm(16, 20);
  position: absolute;
  text-transform: uppercase;
  text-align: justify;
  width: 84%;
  padding: 0 24px 24px;
}

.content[data-layout='1'] {
  .heading {
    top: 5%;
    left: 24px;
    height: 11%;
    width: calc(100% - 48px);
    display: flex;
    align-items: flex-end;
  }

  .image {
    clip-path: inset(20% 24px 50% 24px);

    img {
      width: calc(100% - 48px);
      height: 30%;
      top: 20%;
      left: 24px;
    }
  }

  .text {
    top: 54%;
    left: 0;
  }
}

.content[data-layout='2'],
.content[data-layout='3'] {
  .image {
    clip-path: inset(0 0 0 0);
    height: 105%;
    top: -5%;

    img {
      width: 100%;
      height: 100%;
      top: 0%;
      left: 0;
    }
  }
}

.content[data-layout='2'] {
  color: $gray50;

  .heading {
    top: 24%;
    right: 0;
    height: 30%;
    width: 84%;
    padding: 0 24px 24px;
    display: flex;
    align-items: flex-end;
  }

  .text {
    top: 54%;
    right: 0;
  }
}

.content[data-layout='3'] {
  color: $gray50;

  .heading {
    text-align: right;
    top: 12%;
    right: calc(100% - 24px);
    transform: rotate(-90deg);
    transform-origin: top right;
    width: 100%;
  }

  .text {
    top: 35%;
    right: 0;
    padding-top: 24px;
  }
}
