#temporary-alerts-wrapper {
  max-width: max(50%, 250px);
  position: absolute;
  top: 50px;
  right: 23px;
  z-index: 300;

  @include desktop() {
    top: 75px;
  }

  #temporary-alert {
    background: #1c1c1cc7;
    border-radius: 2px;
    color: white;
    padding: 5px 15px;
    transition: opacity 0.1s;

    &.ended {
      opacity: 0;
    }
  }
}
