@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    padding: 10px;
    transition: 0.75s $ExpoEaseOut;
}

.icon {
    display: flex;

    svg {
        width: 100%;
        height: auto;
    }
}

.outline {
    border: 1px solid $cDark;
}

.outlineInverted {
    border: 1px solid $cDark;
    background-color: $cDark;

    svg path {
        fill: $white;
    }
}