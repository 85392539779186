@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
  max-width: fit-content;
  margin: 0 auto 32px;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &.visible {
    transform: translateY(0);
    opacity: 1;
  }
}
