@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.card {
  background-color: $cDark;
  color: $white;
  border-radius: 8px;
  padding: 12px 40px 18px 16px;
  position: relative;
  cursor: pointer;
}

.title {
  @include fontSize(32, 40);
  font-weight: 600;
  margin-bottom: 6px;
}

.subtitle {
  @include fontSize(18, 24);
  margin-bottom: 8px;
  color: $gray350;
}

.list {
  li {
    padding-left: 35px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        @include iconCheck($white);
    }
  }
}

.icon {
    width: 16px;
    height: auto;
    display: flex;
    position: absolute;
    top: 16px;
    right: 16px;
}