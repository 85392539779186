@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  // padding: 16px 0 80px;
  flex: 1;

  .loadMore {
    margin: auto;
    padding: 20px 0;
    width: 100%;
  }
}
