@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  @keyframes placeholderGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background: linear-gradient(-45deg, #e8a38f, #d3a0b3, #8ec6db, #67c5af);
  background-size: 400% 400%;
  animation: placeholderGradient 15s ease infinite;

  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  height: 215px;

  h1 {
    font-size: 300%;
  }

  p {
    font-size: 120%;
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
