@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  padding: 6px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  svg path {
    // fill: $white;
  }
}

.filled {
  background-color: $cDark;
}

.filledInverted {
  background-color: $gray50;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15));

  svg path {
    fill: $cDark;
  }
}

.noFill {
  background-color: transparent;
}

.outlineInverted {
  border: 1px solid $white;
}

.icon {
  display: flex;

  svg {
    width: 100%;
    height: auto;
  }
}
