@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";
@import "assets/styles/abstracts/breakpoints";

.dots {
    display: flex;
    justify-content: center;
    gap: 24px;
    width: 100%;
    padding: 0 24px;
  }
  
  .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: $gray400;
    border-radius: 100%;
  
    &.active {
      background-color: $cDark;
    }
  }