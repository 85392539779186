.text-center {
  text-align: center;
}

.underline {
  text-decoration-line: underline;
}

.uppercase {
  text-transform: uppercase;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.gap-12 {
  gap: 12px;
}

.w-full {
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.w-8 {
  width: 8px;
}

.w-9 {
  width: 9px;
}

.w-11 {
  width: 11px;
}

.w-12 {
  width: 12px;
}

.w-14 {
  width: 14px;
}

.w-16 {
  width: 16px;
}

.w-18 {
  width: 18px;
}

.w-24 {
  width: 24px;
}

.w-32 {
  width: 32px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-48 {
  margin-bottom: 48px;
}

.pb-16 {
  padding-bottom: 16px;
}

.rotate-180 {
  transform: rotate(180deg);
}