﻿.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: $navBarHeight;
  width: 100%;
  position: absolute;
  bottom: 0;

  @include desktop() {
    padding-top: $navBarHeightLg;
  }

  &.scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .page {
    display: flex;
    flex: 1;

    &_content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 32px 32px;

      &--middle {
        justify-content: center;
      }
    }

    &_actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 38px 32px;

      button {
        margin: 5px;

        &.right-icon {
          position: relative;

          .icon {
            position: absolute;
            right: 10px;
            top: calc(50% - 5px);
          }
        }

        &.secondary {
          background: transparent;
          border: 1px solid #2f2f2f;
          color: #2f2f2f;
        }
      }
    }
  }

  #teaser-video-container {
    z-index: -2;

    #teaser-video-background-cover {
      background: #2f2f2f;
      height: 100%;
      opacity: 0;
      transition: 1s;
      width: 100%;
    }

    #teaser-video-close-button {
      background: white;
      border-radius: 100px;
      cursor: pointer;
      opacity: 0;
      position: absolute;
      right: 15px;
      top: 40px;
      transition: 1s;
      z-index: 1;
    }

    video {
      bottom: 0;
      left: 0;
      margin: auto;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: 1s;
      width: 100%;
    }

    &.shown {
      z-index: 100;

      #teaser-video-background-cover {
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
      }

      #teaser-video-close-button {
        opacity: 1;
      }

      video {
        opacity: 1;
      }
    }
  }
}
