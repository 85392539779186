﻿@mixin fontSize($fontSize, $lineHeight: null, $bfs: $baseFontSize) {
  font-size: ($fontSize/strip-unit($bfs)) + rem;
  @if $lineHeight {
    line-height: $lineHeight/$fontSize;
  } @else {
    line-height: $baseLineHeight;
  }
}

@mixin fontSizeEm($fontSize, $lineHeight: null, $bfs: $baseFontSize) {
  font-size: ($fontSize/strip-unit($bfs)) + em;
  @if $lineHeight {
    line-height: $lineHeight/$fontSize;
  } @else {
    line-height: $baseLineHeight;
  }
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin hover() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin margins($size, $value) {
  .mt#{$size} {
    margin-top: $value;
  }
  .mb#{$size} {
    margin-bottom: $value;
  }
  .ml#{$size} {
    margin-left: $value;
  }
  .mr#{$size} {
    margin-right: $value;
  }
}

@mixin iconCheck($color: $cDark) {
  display: inline-block;
  width: 12px;
  height: 6px;
  border-left: 2px solid $color;
  border-bottom: 2px solid $color;
  transform: rotate(-45deg);
}

@mixin paddingBottomRatio($width, $height) {
  padding-bottom: ($height / $width) * 100%;
}
