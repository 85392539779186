@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.outliner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }
}

.heading {
  width: 100%;
}

.title {
  padding: 12px 4px;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 106px;
}

.actions {
  border: 1px solid black;
  border-radius: 50%;
  transform: rotate(90deg);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  &.visible {
    opacity: 1;
  }

  svg {
    height: 100%;
    width: auto;
  }
}

.status {
  display: flex;
  width: 100%;
  height: 40px;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }

  background-color: $gray400;
  border-radius: 3rem;
  display: inline-block;
  height: 2rem;
  position: relative;
  transition: background-color 0.2s linear;
  width: 100%;

  &::after {
    background-color: black;
    content: '';
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 18px;
    transition: background-color 0.2s linear, left 0.6s $ExpoEaseOut;
  }
}

.cta {
  border-radius: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
  color: $cDark;
  cursor: pointer;
  box-shadow: inset 3px 3px 7px #cecece, inset -3px -3px 7px #ffffff;

  &.publish {
    background-color: $white;
    color: $cDark;
  }
}

.pubishedCta {
    background: linear-gradient(90deg, #f27c66, #fecd8f);
    box-shadow: none;
}

.pubishedSpace {
  &:after {
    background-color: #fff;
    left: auto;
    right: 0.25rem;
  }
}
