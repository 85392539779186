@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  backdrop-filter: blur(6px);
  background-color: transparent;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid $gray200;

  @include desktop() {
    padding-top: 20px;
  }
}

.inner {
  align-items: center;
  display: flex;
  height: $navBarHeight;
  justify-content: space-between;
}

.logo {
  cursor: pointer;
  background-image: url('https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/images/logo.svg');
  background-position: center center;
  height: 100%;
  width: 25%;
}

.avatar {
  cursor: pointer;
  background-size: 24px 34px;
}

.menuButton {
  position: relative;
  width: 40px;
  height: 41px;
  z-index: 41;
  margin-right: -7px;

  span {
    position: absolute;
    left: 7px;
    width: 20px;
    height: 2px;
    border-radius: 2px;
    background-color: $cDark;
    transition: transform 0.8s $ExpoEaseOut, opacity 0.2s linear;
    backface-visibility: hidden;
    will-change: transform, opacity;

    &:nth-child(1) {
      top: 13px;
      transform-origin: center right;
    }

    &:nth-child(2) {
      top: 20px;
      transform-origin: center right;
      transition-delay: 0.05s;
    }

    &:nth-child(3) {
      top: 27px;
      transform-origin: center right;
      transition-delay: 0.1s;
    }
  }

  &.opened {
    span {
      &:nth-child(1) {
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        transform: scaleX(0);
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(45deg);
      }
    }
  }
}

.closeButton {
  position: relative;
  width: 40px;
  height: 41px;
  z-index: 41;
  margin-right: -7px;

  span {
    position: absolute;
    left: 7px;
    width: 20px;
    height: 2px;
    border-radius: 2px;
    background-color: $cDark;
    transition: transform 0.8s $ExpoEaseOut, opacity 0.2s linear;
    backface-visibility: hidden;
    will-change: transform, opacity;

    &:nth-child(1) {
      top: 13px;
      transform-origin: center right;
      transform: rotate(-45deg);
    }

    &:nth-child(2) {
      top: 20px;
      transform-origin: center right;
      transition-delay: 0.05s;
      transform: scaleX(0);
      opacity: 0;
    }

    &:nth-child(3) {
      top: 27px;
      transform-origin: center right;
      transition-delay: 0.1s;
      transform: rotate(45deg);
    }
  }
}

.login {
  color: $cDark;
}
