@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.wrapper {
  display: flex;
  justify-content: center;
  
  @include desktop() {
    flex: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.container {
  width: 100%;
  padding: 16px 64px 64px;
}

.ctaSave {
  cursor: pointer;
  margin-bottom: 32px;
  margin-top: 16px;
}
