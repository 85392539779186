@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.button {
  background: linear-gradient(145deg, #f0f0f0, #f2f2f2);
  border-radius: 50%;
  box-shadow:  3px 3px 7px #bebebe, -3px -3px 7px #ffffff;
  height: 36px;
  margin: auto;
  width: 36px;

  &.selected {border-radius: 150px;
    background: #f2f2f2;
    box-shadow: inset 3px 3px 7px #cecece, inset -3px -3px 7px #ffffff;

  }
}

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  // height: 64px;
  padding: 8px;
  // background-color: #f2f2f2;
  background-color: transparent;
  display: grid;
  // grid-template-columns: 1fr 48px 1fr;
  grid-template-columns: 1fr;
  gap: 8px;
  z-index: 1;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;

  @include desktop() {
    position: sticky;
  }

  &.visible {
    transform: translateY(0);
  }
}

.filled {
  background: $charcoal;
}

.item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin: 4px auto 8px auto;

  a {
    color: $gray300;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 80%;
    text-decoration: none;
    position: relative;
  }
}

.icon {
  align-items: center;
  border-radius: 8px;
  border: 2px solid transparent;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 6rem;

  svg {
    display: block;
    width: 100%;
    height: auto;

    path {
      fill: white;
    }
  }
}

.plus {
  display: block;
  width: 14px;
}

.generate {
  padding: 12px;

  svg {
    transform: translate(1.5px, -1.5px);
  }
}
