@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 4;
  // padding: calc($navBarHeight + 24px) 16px calc(64px + 24px);

  @include desktop() {
    // max-height: 85vh;
    // max-width: 85%;
    overflow-y: hidden;
    margin: auto;
    // padding: calc($navBarHeightLg + 24px) 16px calc(64px + 24px);
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.75s $ExpoEaseOut;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.container {
  box-shadow: -0.5rem 0rem 1rem #00000047;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
  background-color: $gray50;
  border-radius: 24px;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  //transition: all 0.5s $ExpoEaseInOut;
}

.body {
  padding: 1rem 1rem 2rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: hidden;

  .confirmationText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    overflow-x: hidden;

    p {
      max-width: 100%;
      text-wrap: wrap;
      white-space: normal;
      word-break: break-word;
    }
  }

  .sizer {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    margin: 1rem 0 2rem 0;
    max-width: 100%;
    width: 100%;
  }
}

.icon {
  display: flex;

  svg {
    width: 100%;
    height: auto;
  }
}

.optionsLine {
  border-radius: 1rem;
  // box-shadow:  3px 3px 7px #bebebe, -3px -3px 7px #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  // border-bottom: 1px solid $gray200;
  // padding: 8px 5px 8px 8px;

  .icon {
    width: 6px;
  }
}

.twoCols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.verticalCols {
  display: grid;
  gap: 16px;
}

.themeSelector {
  display: flex;
  gap: 16px;
}

.ctaTheme {
  flex: 1;
  @include fontSize(18, 18);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 8px;
  border: 2px solid $cDark;
  transition: 0.75s $ExpoEaseOut;
  cursor: pointer;

  &.light {
    background-color: $pearl;
  }

  &.dark {
    background-color: $cDark;
    color: $white;
  }

  &.selected {
    border: 2px solid #59f;
  }
}

.level,
.options {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  gap: 1rem;
  width: 100%;
}


.addSectionWrapper {
  grid: 1fr;
  width: 100%;

  h2 {
    color: #F27C66;
    font-weight: bold;
  }
}

.addSectionButton {
  border-radius: 1rem;
  box-shadow:  3px 3px 7px #bebebe, -3px -3px 7px #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin: 1rem;
  padding: 1rem;
  width: 100%;

  h3 {
    font-weight: bold;
  }
}

.fullsize {
  height: 100%;
}
