@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

///////////////////////////////////
// SEARCH FILTER //////////////////
///////////////////////////////////

.SearchFilter__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.SearchFilter__input {
    display: flex;
    border: 2px solid transparent;
    border-radius: 12px;
    position: relative;

    input {
        width: 100%;
        height: 32px;
        padding: 8px;
    }
}

.SearchFilter__icon {
    width: 14px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 12px;
    display: flex;
    align-items: center;

    svg {
        display: block;
        width: 100%;
        height: auto;
    }
}

.SearchFilter__filters {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
}

.SearchFilter__btn {
    background-color: transparent;
    border: 1px solid $cDark;
    border-radius: 9999px;
    padding: 4px 12px;

    &.active {
        background-color: $cDark;
        color: $white;
    }
    
    &:hover {
        @include desktop() {
            background-color: $cDark;
            color: $white;
        }
    }
}