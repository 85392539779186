@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.container {
  width: 100%;
  height: auto;
  margin: 16px;
  max-width: 225px;
  position: relative;
  z-index: 1;
  background-color: $gray50;
  border-radius: 1.5rem;
  transform: translateY(-20px);
  transition: 0.75s $ExpoEaseOut;
  overflow: hidden;
  padding: 0.5rem 0;
}

.list {
    padding: 0 1rem;

    li {
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        padding: 14px 14px;
        cursor: pointer;
        transition: 0.75s $ExpoEaseOut;
        font-weight: bold;
        color: $gray500;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          @include desktop() {
            background-color: $gray100;
          }
        }
    }
}

.icon {
    display: flex;
    color: $gray500;
    width: 14px;
  
    svg {
      width: 100%;
      height: auto;
    }
  }
