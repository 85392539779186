@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.navigation {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  width: 100%;
  height: 100%;
  background-color: $video-background-color;
  overflow: hidden;
  padding: 100px 16px 24px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transform: translateX(101%);
  transition: transform 0.8s $ExpoEaseOut;
  will-change: transform;
}

.visible {
  transform: translateX(0);
}
