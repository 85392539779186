@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

$sizeUnit: 24px;

.overlay {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($cDark, 0.8);
}

.container {
  position: fixed;
  top: $navBarHeight * 1.25;
  left: $sizeUnit;
  width: calc(100% - $sizeUnit * 2);
  height: calc(100% - $navBarHeight * 2.5);
  z-index: 3;
  overflow: hidden;
  border-radius: 8px;
}

.header {
  width: 100%;
  height: $sizeUnit * 2;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.slider {
  width: 100%;
  height: calc(100% - $sizeUnit * 2);
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $gray50;
}

.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.slides {
  align-items: stretch;
  background-color: $gray50;
  display: flex;
  height: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 18px;
  width: 100%;
}

.slide {
  background-color: $gray50;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  height: 100%;
  margin: 2px 0;
  padding: 8px 4px;
  width: 100%;
}

.media {
  border: 1px solid $gray200;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray50;

  @media (max-width: 400px) {
    padding-bottom: 100%;
  }

  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.body {
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px 24px 84px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: $white;
}

.dots {
  position: absolute;
  top: 18px;
  left: 0;
  display: flex;
  justify-content: center;
  gap: 24px;
  width: 100%;
  padding: 0 24px;
}

.dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: $gray400;
  border-radius: 100%;

  &.active {
    background-color: $cDark;
  }
}

.nav {
  position: absolute;
  width: 100%;
  height: $sizeUnit * 2;
  bottom: 16px;
  display: flex;
  justify-content: center;
}

.buttons {
  width: 136px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  width: $sizeUnit * 2;
  height: $sizeUnit * 2;
  border-radius: $sizeUnit * 2;
  border: 1px solid $cDark;
  opacity: 1;

  &:disabled {
    opacity: 0.5;
  }
}

.text {
  width: calc(100% - $sizeUnit * 2);
  position: absolute;
  top: 48px;
}
