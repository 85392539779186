@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
    color: $yellow;
    font-size: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
    margin-bottom: 8px;
}

.data {
    align-items: center;
    border: 1px solid $orangeDark;
    border-radius: 1rem;
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 10px;
    
  &.share {
    background: black;
    border-color: black;
    color: white;
    justify-content: flex-start;
  }
}


.ico {
    display: flex;
    width: 16px;
    
    svg {
        width: 100%;
        height: auto;

        path {
            fill: $peach;
        }
    }
}
