@import 'assets/styles/abstracts/variables';

.container {
  border-radius: 1rem;
  width: 100%;
  position: relative;
}

.content {
  background-color: white;
  position: relative;
  font-size: 11px;
  overflow: hidden;
  cursor: grab;
}

.contentEditView {
  box-shadow: 0.5rem 0.5rem 1rem #00000047;
  background-color: white;
  border-radius: 1rem;
  position: relative;
  font-size: 11px;
  overflow: hidden;
  cursor: grab;
}

.contentFullPageView {
  background-color: white;
  position: relative;
  font-size: 14px;
  overflow: hidden;
  cursor: grab;
}

.edit {
  background-color: transparent;
  position: absolute;
  z-index: 1;

  &.topRight {
    top: -8px;
    right: -8px;
  }

  &.bottomRight {
    bottom: 0.5rem;
    right: 0.5rem;
  }

  &.bottomRightNoImage {
    bottom: 0.5rem;
    right: 0.5rem;
  }

  &.topLeft {
    top: -16px;
    left: -16px;
  }

  &.bottomLeft {
    bottom: 16px;
    left: 16px;
  }

  &.bottomLeftNoImage {
    bottom: 26px;
    left: 16px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $gray300;
  margin: 24px 0;
  position: relative;
}

.delete {
  width: 64px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addSection {
  background-color: $charcoal;
  border-radius: 100%;
  position: absolute;
  top: 100%;
  right: -48px;
  margin: 8px 0;
}
