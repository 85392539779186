@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}

.container {
  width: 100%;
  margin: 16px;
  max-width: 250px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray100;
  border-radius: 8px;
  transform: translateY(-20px);
  transition: 0.75s $ExpoEaseOut;
  overflow: hidden;

  ul {
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.deletionText {
  padding: 10px;
  text-wrap: wrap;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cta {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 14px 14px;
  transition: 0.75s $ExpoEaseOut;
  background-color: $white;
  border-radius: 8px;
  width: 100%;
  position: relative;

  &:hover {
    @include desktop() {
      background-color: $gray100;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 16px;
  height: 100%;

  svg {
    width: 100%;
    height: auto;
  }
}

.close {
  position: absolute;
  padding: 8px;
  top: 0;
  right: 0;
}
