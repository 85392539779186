@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.outliner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }
}

.heading {
  width: 100%;
}

.title {
  padding: 12px 4px;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 106px;
}

.actions {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }

  svg {
    height: 100%;
    width: auto;
  }
}

.iconList {
  display: flex;
  justify-content: space-between;
  padding: 0 4px 6px;
}

.iconItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid $cDark;
}

.icon {
    display: flex;
    margin-top: 1px;

  svg {
    display: flex;
    width: 100%;
    height: auto;
  }
}
