﻿.vhidden{
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.pRelative{ position: relative; }

.text-left{ text-align: left; }
.text-center{ text-align: center; }
.text-right{ text-align: right; }

@include margins('1',$sp1);
@include margins('2',$sp2);
@include margins('3',$sp3);
@include margins('4',$sp4);
@include margins('5',$sp5);
