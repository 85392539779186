﻿$cDarker: #1c1c1c;
$cDark: #2f2f2f;
$charcoal: #2f2f2f;
$pearl: #fffefe;
$gray50: #f4f4f4;
$gray100: #eaeaea;
$gray200: #d7d7d7;
$gray300: #acacac;
$gray350: #9a9899;
$gray400: #828282;
$gray500: #595959;
$gray700: #3f3f3f;
$orangeLight: #fff5e6;
$orangeDark: #ffbf41;
$white: #fff;
$error: #d21c1c;
$yellow: #f09c1d;
$yellow50: #fff5e6;
$peach: #f69774;

$video-background-color: $gray50;

//  BASE
//////////////////////////////////////////////
$baseFontFamily: 'Roobert', Helvetica, sans-serif;
$baseFontSize: 16px;
$baseLineHeight: 1.3;
$baseLetterSpacing: 0;
// $baseBackgroundColor: #fff;
$baseBackgroundColor: $video-background-color;
$baseTextColor: $cDark;
$navBarHeight: 60px;
$navBarHeightLg: 80px;

$selectionBackgroundColor: #999;
$selectionColor: #232323;

// SPACERS
///////////////////////////////////////////////////////////
$sp0: 0;
$sp1: 4px;
$sp2: 8px;
$sp3: 12px;
$sp4: 16px;
$sp5: 24px;
$sp6: 32px;

//  EASES
///////////////////////////////////////////////////////////
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(0.8, 0, 0.2, 1);

// GSAP EASINGS
$Power0EaseNone: cubic-bezier(0.25, 0.25, 0.75, 0.75);
$Power1EaseIn: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$Power1EaseOut: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$Power1EaseInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$Power2EaseIn: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$Power2EaseOut: cubic-bezier(0.215, 0.61, 0.355, 1);
$Power2EaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1);
$Power3EaseIn: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$Power3EaseOut: cubic-bezier(0.165, 0.84, 0.44, 1);
$Power3EaseInOut: cubic-bezier(0.77, 0, 0.175, 1);
$Power4EaseIn: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$Power4EaseOut: cubic-bezier(0.23, 1, 0.32, 1);
$Power4EaseInOut: cubic-bezier(0.86, 0, 0.07, 1);
$CircEaseIn: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$CircEaseOut: cubic-bezier(0.075, 0.82, 0.165, 1); // wip
$CircEaseInOut: cubic-bezier(0.785, 0.135, 0.15, 0.86); // wip
$ExpoEaseIn: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ExpoEaseOut: cubic-bezier(0.19, 1, 0.22, 1);
$ExpoEaseInOut: cubic-bezier(1, 0, 0, 1);
$SineEaseIn: cubic-bezier(0.47, 0, 0.745, 0.715);
$SineEaseOut: cubic-bezier(0.39, 0.575, 0.565, 1);
$SineEaseInOut: cubic-bezier(0.445, 0.05, 0.55, 0.95);

$iPhone15Height: 852px;
$iPhone15Width: 393px;
$projectIframeRatio: 9/13;
$projectSectionRatio: 9/16;

$page-option-scale-factor: 0.6;
$page-section-option-width: calc($iPhone15Width * $page-option-scale-factor);
