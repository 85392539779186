@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  font-size: 12px;

  &[data-layout='1'] {
    .heading {
      text-align: right;
      top: 24px;
      right: calc(100% - 24px);
      width: 100%;
      transform: rotate(-90deg);
      transform-origin: top right;
    }
  }

  &[data-layout='2'] {
    .heading {
      hyphens: auto;
      left: 0;
      padding: 50px 24px;
      text-align: left;
      top: 0;
      width: 100%;
      word-wrap: break-word;
    }
  }

  &[data-layout='3'] {
    .heading {
      text-align: right;
      top: 0;
      right: 0;
      width: 90%;
      height: 100%;
      padding: 50px 24px;
      display: flex;
      align-items: center;
    }
  }
}

.background {
  aspect-ratio: $projectSectionRatio;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.heading {
  color: $gray50;
  position: absolute;
  @include fontSizeEm(36, 36);
  font-family: 'AtiplaND';
  text-transform: uppercase;
}

.swipe {
  width: 64px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swipeIco {
  display: flex;
  width: 16px;
  animation: swipe 5s infinite;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
}

@keyframes swipe {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateX(-5px);
	}
	40% {
		transform: translate(0);
	}
}
