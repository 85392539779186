﻿*, *::before, *::after, *:before, *:after, *:focus, *:active{
    box-sizing: border-box;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-interpolation-mode: bicubic;
    -webkit-tap-highlight-color: transparent;
}

@import 'ress';

html{
    font-size: $baseFontSize;
    line-height: $baseLineHeight;
}

html.is-locked,
html.is-locked body {
    /* want to fix the height to the window height */
    height: 100dvh;

    /* want to block all overflowing content */
    overflow: hidden;

    /* want to exclude padding from the height */
    box-sizing: border-box;
}


body{
    background-color: $baseBackgroundColor;
    font-family: $baseFontFamily;
    letter-spacing: $baseLetterSpacing;
    color: $baseTextColor;
}

*::selection{ background: $selectionBackgroundColor; color: $selectionColor; }
*::-moz-selection{ background: $selectionBackgroundColor; color: $selectionColor; }
*::-webkit-selection{ background: $selectionBackgroundColor; color: $selectionColor; }

img{
    max-width: 100%;
}

a{
    &, &:visited{
        color: currentColor;
        text-decoration: none;
    }

    &:hover{
        text-decoration: underline;
    }
}

p{
    margin: 0;

    & + p{
        margin-top: 1.5rem;
    }
}

ul{
    padding: 0;
    margin: 0;

    &, li{
        list-style: none;
    }
}

h1, h2, h3, h4, h5, h6{
    margin: 0;
    font-weight: normal;
    font-size: 1rem;
}
