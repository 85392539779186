@import 'assets/styles/abstracts/variables';

.container {
    background-color: white;
    cursor: pointer;
    border-radius: 8px;
    padding: 16px;
    display: grid;
    align-items: center;
    grid-template-columns: min(65px, 30%) 1fr auto;
    gap: 16px;
}

.icon {
    background-color: $gray50;
    border-radius: 4px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
