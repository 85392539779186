@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  display: flex;
  width: 100%;
  aspect-ratio: $projectSectionRatio;
}

.heading {
  @include fontSizeEm(32, 32);
  font-family: 'AtiplaND';
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 16%;
  display: flex;
  align-items: center;

  position: relative;
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));

  span {
    display: inline-block;
    padding: 0 10px;
    white-space: nowrap;
  }
}

.headingInner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 5s linear infinite;
  animation-play-state: running;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

.gallery,
.image,
.dots,
.text {
  transition: all 0.3s $ExpoEaseInOut;
}


.gallery {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  
}

.image {
  position: absolute;
  opacity: 1;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.dots {
  position: absolute;
  top: 69%;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  opacity: 0;

  li {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: $cDark;
    opacity: 0.5;

    &:nth-child(1) {
      opacity: 1;
    }
  }
}

.text {
  @include fontSizeEm(16, 20);
  text-transform: uppercase;
  text-align: justify;
  padding: 0 50px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 72%;
}

.content[data-layout='1'] {
  .image {
    &:nth-child(1) {
      width: 38%;
      aspect-ratio: 134/237;
      top: 16%;
      left: 31%;
    }

    &:nth-child(2) {
      width: 33%;
      aspect-ratio: 130/75;
      top: 24%;
      left: 2%;
    }

    &:nth-child(3) {
      aspect-ratio: 100/70;
      left: 60%;
      top: 44%;
      width: 38%;
    }

    &:nth-child(4) {
      width: 21%;
      aspect-ratio: 78/108;
      top: 46%;
      left: 5%;
    }

    &:nth-child(5) {
      display: none;
      // width: 30%;
      // aspect-ratio: 114/140;
      // top: 48%;
      // left: 71%;
    }
  }
}

.content[data-layout='2'],
.content[data-layout='3'] {
  .dots {
    opacity: 1;
  }
}

.content[data-layout='2'] {
  .gallery {
    overflow-x: hidden;
    
    &::-webkit-scrollbar {
      height: 0rem;
      width: 0rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  &.overflow {
    .gallery {
      overflow-x: scroll;
    }
  }

  .image {
    $image-slider-starting-position: 19.5%;
    $image-slider-width: 66.5%;
    aspect-ratio: 230/340;
    top: 16%;
    width: 61%;

    &:nth-child(1) {
      left: calc($image-slider-starting-position + ($image-slider-width * 0));
    }

    &:nth-child(2) {
      left: calc($image-slider-starting-position + ($image-slider-width * 1));
    }

    &:nth-child(3) {
      left: calc($image-slider-starting-position + ($image-slider-width * 2));
    }

    &:nth-child(4) {
      left: calc($image-slider-starting-position + ($image-slider-width * 3));
      margin-right: 30%;

      &:after {
        content: '.';
        color: transparent;
        display: block;
        width: 130%;
      }
    }

    &:nth-child(5) {
      display: none;
      // left: calc($image-slider-starting-position + ($image-slider-width * 4));
    }
  }
}

.content[data-layout='3'] {
  color: $gray50;

  .gallery {
    overflow: hidden;
    
    &::-webkit-scrollbar {
      height: 0rem;
      width: 0rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  &.overflow {
    .gallery {
      overflow-x: scroll;
    }
  }

  .image {
    $image-slider-starting-position: 0%;
    $image-slider-width: 100%;

    aspect-ratio: $projectSectionRatio;
    top: 0;
    transform-origin: center;
    width: 100%;

    &:nth-child(1) {
      left: calc($image-slider-starting-position + ($image-slider-width * 0));
    }

    &:nth-child(2) {
      left: calc($image-slider-starting-position + ($image-slider-width * 1));
    }

    &:nth-child(3) {
      left: calc($image-slider-starting-position + ($image-slider-width * 2));
    }

    &:nth-child(4) {
      left: calc($image-slider-starting-position + ($image-slider-width * 3));
    }

    &:nth-child(5) {
      left: calc($image-slider-starting-position + ($image-slider-width * 4));
    }
  }

  .dots {
    top: 80%;
    
    li {
      background-color: $gray50;
    }
  }

  .text {
    bottom: 25%;
    top: auto;
  }
}
