@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  display: flex;
  flex-direction: column;
  color: $cDark;

  textarea {
    flex: 1 1 auto;
    overflow: hidden;
    resize: none;
    border: 1px solid $gray200;
    border-radius: 12px;
    width: 100%;
    padding: 8px 12px;
  }
}

.message {
    margin-top: 4px;
}
