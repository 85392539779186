@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.container {
  background-color: $video-background-color;
  padding: 0 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  justify-content: center;

  .loadMore {
    padding: 0 calc(7% + 16px) 0 7%;
  }
}

.slider {
  margin: 0 -16px;
  padding: 0 16px;
  overflow-x: scroll;
  white-space: nowrap;
  // flex: 1;

  &::-webkit-scrollbar {
    height: 0rem;
    width: 0rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  :global(#load-more-projects-button) {
    margin: 50% auto auto 24px;
  }
}

.projects {
  width: 100%;
  display: flex;
  align-items: stretch;
  padding: 4px 0;
}

.main {
  width: 100%;
  flex: 1;
}

.help {
  position: absolute;
  top: 1rem;
  right: 24px;
}
