.container {
    cursor: pointer;
}

.title {
    margin-bottom: 6px;
}

.subtitle {
    margin-top: 4px;
}