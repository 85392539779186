@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  display: block;
  width: 100%;
  padding: 9px 24px;
}

.link {
  @include fontSizeEm(16, 20);
  padding: 6px 0;
  color: $cDark;
  text-transform: uppercase;
  transition: all 0.6s $ExpoEaseInOut;
  position: relative;

  .inner {
    display: inline-block;
    width: 100%;
  }
}

.icon {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  width: 8px;
  height: 100%;

  svg {
    display: block;
    width: 100%;
  }
}

.content[data-layout='1'] {
  .link {
    padding-left: 0px;
  }

  .icon {
    right: 0;
  }
}

.content[data-layout='3'] {
  .link {
    text-align: right;
  }
}

.content[data-layout='1'],
.content[data-layout='3'] {
  .link {
    border-bottom: 1px solid $cDark;
  }
}

.content[data-layout='2'],
.content[data-layout='3'] {
  .link {
    padding-left: 20px;
  }
  .icon {
    left: 0;

    svg {
      transform: rotate(45deg);
    }
  }
}
