.p-security{
    .form{
        margin-bottom: 40px;

        &_additional{
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;

            a{
                text-decoration: underline;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}