@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.header {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
    
    @include desktop() {
        position: absolute;
    }
}