@import 'assets/styles/abstracts/variables';
@import 'assets/styles/abstracts/mixins';
@import 'assets/styles/abstracts/breakpoints';

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
}

.cta {
  @include fontSizeEm(16, 20);
  padding: 10px 12px;
  color: $gray50;
  border-radius: 0;
  transition: all .6s $ExpoEaseInOut;
}

.content[data-layout='1'],
.content[data-layout='2'] {
  .cta {
    background-color: $cDark;
  }
}

.content[data-layout='2'] {
  .cta {
    border-radius: 9999px;
  }
}

.content[data-layout='3'] {
  .cta {
    color: $cDark;
    background-color: $white;

    span {
      display: inline-block;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        left: 0;
        bottom: -3px;
        padding-top: 1px;
        background-color: $cDark;
      }
    }
  }
}
